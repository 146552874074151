import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import { graphql } from 'gatsby'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { useSiteState } from '../lib/siteContext'

const PostCard = ({ title, postDate, image, slug, url, category }) => {
  return (
    <div className="col-span-1">
      <div className="relative w-full pb-card-bio bg-doc-text">
        {category === 'Media / Press' ? (
          <a href={url} target="_blank" rel="noreferrer">
            {image && (
              <img
                className="absolute inset-0 w-full h-full object-cover"
                src={image.asset.url}
                alt={image.alt}
              />
            )}
          </a>
        ) : (
          <Link to={`/news/${slug.current}`}>
            {image && (
              <img
                className="absolute inset-0 w-full h-full object-cover"
                src={image.asset.url}
                alt={image.alt}
              />
            )}
          </Link>
        )}
      </div>
      <div className="flex justify-between items-baseline my-gutter">
        <span>{category}</span>
        <span>{postDate}</span>
      </div>
      {slug && (
        <Link
          to={`/news/${slug.current}`}
          className="heading uppercase text-h6 cursor-pointer"
        >
          {title}
        </Link>
      )}
    </div>
  )
}
const BlogPage = ({
  data: {
    allPosts,
    allMedia,
    allEvents,
    allPostsCorporate,
    allPostsMat,
    allPostsYacht,
  },
}) => {
  const { homeHeader, setHomeHeader } = useSiteState()

  useEffect(() => {
    if (homeHeader === true) {
      setHomeHeader(false)
    }
    document.documentElement.style.setProperty(`--doc-bg`, '#ffffff')
    document.documentElement.style.setProperty(`--doc-text`, '#18227B')
  }, [setHomeHeader, homeHeader])

  return (
    <>
      {/* <div className="page-nav z-20 absolute lg:fixed top-60 lg:top-screen-half lg:transform lg:-translate-y-full right-gutter text-doc-text flex flex-col heading uppercase poounter-events-none lg:opacity-full lg:pointer-events-all">
        <AnchorLink
          className="mb-2 trans-std"
          to={`#community`}
          title="All News"
        >
          - All News
        </AnchorLink>
        <AnchorLink
          className="mb-2 trans-std"
          to={`#community`}
          title="Community"
        >
          - Community
        </AnchorLink>
        <AnchorLink className="mb-2 trans-std" to={`#media`} title="Media">
          - Media
        </AnchorLink>
        <AnchorLink className="mb-2 trans-std" to={`#events`} title="Events">
          - Events
        </AnchorLink>
      </div> */}
      <div className="grid-std pt-40 lg:pt-bannerTop text-fat-blue">
        {/* All Posts */}
        <div
          id="community"
          className="col-start-2 col-end-8 lg:col-end-18 mb-space-s"
        >
          <div className="heading text-h4 lg:text-h3 xl:text-h1 uppercase mb-4 lg:mb-8">
            COMMUNITY
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-gutter">
            {allPosts.edges.map((post, index) => (
              <PostCard {...post.node} category="Community" />
            ))}
          </div>
          <div className="flex w-full justify-end heading text-h5 mt-8">
            <Link to={`/news/community`}>MORE</Link>
          </div>
        </div>
        {/* All Corporate  */}
        <div
          id="corporate"
          className="col-start-2 col-end-8 lg:col-end-18 mb-space-s"
        >
          <div className="heading text-h4 lg:text-h3 xl:text-h1 uppercase mb-4 lg:mb-8">
            Corporate
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-gutter">
            {allPostsCorporate.edges.map((post, index) => (
              <PostCard {...post.node} category="Corporate" />
            ))}
          </div>
          <div className="flex w-full justify-end heading text-h5 mt-8">
            <Link to={`/news/corporate`}>MORE</Link>
          </div>
        </div>
        {/* All Events  */}
        <div
          id="events"
          className="col-start-2 col-end-8 lg:col-end-18 mb-space-l hidden"
        >
          <div className="heading text-h4 lg:text-h3 xl:text-h1 uppercase mb-4 lg:mb-8">
            Events
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-gutter">
            {allPosts.edges.map((post, index) => (
              <PostCard {...post.node} category="Events" />
            ))}
          </div>
          <div className="flex w-full justify-end heading text-h5 mt-8">
            <Link to={`/news/events`}>MORE</Link>
          </div>
        </div>
        {/* All Yacht  */}
        <div
          id="yacht"
          className="col-start-2 col-end-8 lg:col-end-18 mb-space-l "
        >
          <div className="heading text-h4 lg:text-h3 xl:text-h1 uppercase mb-4 lg:mb-8">
            Yachting
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-gutter">
            {allPostsYacht.edges.map((post, index) => (
              <PostCard {...post.node} category="Yacht" />
            ))}
          </div>
          <div className="flex w-full justify-end heading text-h5 mt-8">
            <Link to={`/news/yacht`}>MORE</Link>
          </div>
        </div>
        {/* All Media  */}
        <div
          id="media"
          className="col-start-2 col-end-8 lg:col-end-18 mb-space-s"
        >
          <div className="heading text-h4 lg:text-h3 xl:text-h1 uppercase mb-4 lg:mb-8">
            Media / Press
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-gutter">
            {allMedia.edges.map((post, index) => (
              <PostCard {...post.node} category="Media / Press" />
            ))}
          </div>
          <div className="flex w-full justify-end heading text-h5 mt-8">
            <Link to={`/news/media-press`}>MORE</Link>
          </div>
        </div>
        {/* All MAT  */}
        <div
          id="mums-all-together"
          className="col-start-2 col-end-8 lg:col-end-18 mb-space-l "
        >
          <div className="heading text-h4 lg:text-h3 xl:text-h1 uppercase mb-4 lg:mb-8">
            Mums All Together
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-gutter">
            {allPostsMat.edges.map((post, index) => (
              <PostCard {...post.node} category="Mums All Together" />
            ))}
          </div>
          <div className="flex w-full justify-end heading text-h5 mt-8">
            <Link to={`/news/mums-all-together`}>MORE</Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogPage

export const pageQuery = graphql`
  query blogPortalQuery {
    allEvents: allSanityEvent(
      limit: 4
      sort: { fields: postDate, order: DESC }
      skip: 0
    ) {
      edges {
        node {
          title
          images {
            asset {
              url
            }
          }
          slug {
            current
          }
          image {
            asset {
              url
            }
          }
          description
          postDate(formatString: "DD MMMM YYYY")
        }
      }
    }
    allMedia: allSanityMedia(
      limit: 4
      sort: { fields: postDate, order: DESC }
      skip: 0
    ) {
      edges {
        node {
          title
          image {
            asset {
              url
            }
          }
          slug {
            current
          }
          postDate(formatString: "DD MMMM YYYY")
          url
        }
      }
    }
    allPosts: allSanityArticle(
      limit: 4
      sort: { fields: postDate, order: DESC }
      skip: 0
    ) {
      edges {
        node {
          title
          image {
            asset {
              url
            }
          }
          postDate(formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
    allPostsCorporate: allSanityArticleCorporate(
      limit: 4
      sort: { fields: postDate, order: DESC }
      skip: 0
    ) {
      edges {
        node {
          title
          image {
            asset {
              url
            }
          }
          postDate(formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
    allPostsMat: allSanityArticleMat(
      limit: 4
      sort: { fields: postDate, order: DESC }
      skip: 0
    ) {
      edges {
        node {
          title
          image {
            asset {
              url
            }
          }
          postDate(formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
    allPostsYacht: allSanityArticleYacht(
      limit: 4
      sort: { fields: postDate, order: DESC }
      skip: 0
    ) {
      edges {
        node {
          title
          image {
            asset {
              url
            }
          }
          postDate(formatString: "DD MMMM YYYY")
          slug {
            current
          }
        }
      }
    }
  }
`
